import { ExpandableTextSection, SummaryTable, SummaryTableRow, commonText } from '@mid-react-common/common';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { ProductDetailsContainer } from './ProductDetails.styles';
import { addinsText } from '../..';
import { ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import { isUndefined } from 'lodash';
import { productPreviewTableTestIds } from '@mid-react-common/common';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { productDefinitionEngines } from 'mid-types';

const productDetailsText = addinsText.productDetails;

interface ProductDetailsProps {
  title?: string;
  productCategory: string;
  productFamily: string;
  representations: string[];
  isConfigurable?: boolean;
  releaseStatus?: ReleaseStatusValue;
  releaseNumber?: number;
  releaseNotes?: string;
  authoringAppEngine?: string;
}

const PRODUCT_REPRESENTATION_SEPARATOR = '; ';

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  title,
  productCategory,
  productFamily,
  representations,
  releaseStatus,
  releaseNumber,
  releaseNotes,
  isConfigurable,
  authoringAppEngine,
}): JSX.Element => {
  const { enableStaticContent } = useFlags();

  const productDetailsData: SummaryTableRow[] = useMemo(() => {
    const rows = [];
    const authoringAppNames: Record<string, string> = {
      [productDefinitionEngines.INVENTOR]: commonText.productReleaseDetails.inventorAddin,
      [productDefinitionEngines.FUSION]: commonText.productReleaseDetails.fusionAddin,
    };

    if (authoringAppEngine && authoringAppEngine in authoringAppNames) {
      rows.push({
        title: commonText.productReleaseDetails.authoredIn,
        value: authoringAppNames[authoringAppEngine],
      });
    }

    rows.push(
      {
        title: productDetailsText.revitCategoryProductDetails,
        value: productCategory,
      },
      {
        title: productDetailsText.revitFamilyProductDetails,
        value: productFamily,
      },
    );

    if (representations.length) {
      rows.push({
        title: productDetailsText.revitRepresentation,
        value: representations.join(PRODUCT_REPRESENTATION_SEPARATOR),
      });
    }

    if (enableStaticContent && !isUndefined(isConfigurable)) {
      rows.push({
        title: productDetailsText.revitPublishedAs,
        value:
          isConfigurable === false
            ? productDetailsText.revitPublishedAsStatic
            : productDetailsText.revitPublishedAsConfigurable,
      });
    }

    // There are views where we don't want to display the status &
    // number row if a value is not passed down. e.g., configure product in revit addin
    if (!isUndefined(releaseNumber) && !isUndefined(releaseStatus)) {
      rows.push(
        {
          title: productDetailsText.revitReleaseStatus,
          value: releaseStatus || '',
        },
        {
          title: productDetailsText.revitReleaseNumber,
          value: releaseNumber?.toString() || '',
        },
      );
    }

    rows.push({
      title: productDetailsText.revitReleaseNotes,
      value: (
        <ExpandableTextSection
          className={!releaseNotes ? 'mid-status-secondary' : ''}
          content={releaseNotes || commonText.releaseNotes.notesUnspecified}
        />
      ),
    });

    return rows;
  }, [
    authoringAppEngine,
    enableStaticContent,
    isConfigurable,
    productCategory,
    productFamily,
    releaseNotes,
    releaseNumber,
    releaseStatus,
    representations,
  ]);

  return (
    <ProductDetailsContainer>
      <Typography variant="h2" gutterBottom data-testid={productPreviewTableTestIds.releaseName}>
        {title}
      </Typography>
      <SummaryTable data={productDetailsData} removeBackground addRowsBorder />
    </ProductDetailsContainer>
  );
};
