import { CachedVariantMetaInfo, CachedVariant } from 'mid-types';
import { logError } from 'mid-utils';
import { CachedFileInfo, FileMetaData, VariantInfoType } from '../../interfaces/localCache';
import { downloadFileFromUrl } from './filesystem';
import { downloadFileToLocalCache, getLocallyCached, writeToCache } from './localCache';
import { AccBridgeDownloadUrlQueryParams, DcApiService, InversifyTypes, inversifyContainer } from 'mid-api-services';
import {
  DCTemplateContext,
  DownloadURLPayload,
  DownloadUrlResponse,
  OutputStatus,
  OutputType,
  OutputTypes,
  PostVariantPayload,
  Variant,
  VariantInput,
  VariantOutput,
} from '@adsk/offsite-dc-sdk';
import { AccBridgeSourceProjectDataQueryParams } from 'mid-api-services';
import { calculateVariantHash } from '../variantHash';

interface GetVariantArgs {
  projectId: string;
  productId: string;
  variantId: string;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
}

export const getVariant = async ({
  projectId,
  productId,
  variantId,
  incomingAccBridgeData,
}: GetVariantArgs): Promise<Variant> => {
  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  const variant: Variant = await dcApiService.getVariant({
    projectId,
    productId,
    variantId,
    incomingAccBridgeData,
  });

  return variant;
};

const translateToCachedVariantMetaInfo = (variant: Variant): CachedVariantMetaInfo => ({
  name: variant.name,
  context: JSON.stringify(variant.context),
  dataSetLocation: variant.dataSetLocation,
  schemaVersion: `${variant.schemaVersion}`,
  inputs: JSON.stringify(variant.inputs),
});

export const getVariantFromLocalCache = async (
  projectId: string,
  productId: string,
  variantPayload: PostVariantPayload,
  representation?: string,
): Promise<Variant | null> => {
  if (variantPayload.release !== void 0) {
    // check if variant already cached
    const variantId = calculateVariantHash(projectId, productId, variantPayload.release, variantPayload.inputs);
    const cached = await getLocallyCached(variantId);

    if (
      cached &&
      cached.length > 0 &&
      variantPayload.outputs.every((output) =>
        cached.find((c) => output.type === OutputType.RFA && output.type === c.type && output.modelState === c.modelState),
      ) &&
      cached.some((cachedOutput) => cachedOutput.type === OutputType.RFA && cachedOutput.modelState === representation)
    ) {
      const cachedOutputs = cached.filter((c) => Object.values<string>(OutputType).indexOf(c.type) >= 0);
      const cachedMetaInfo = cached.find((c) => c.type === VariantInfoType);
      const thumbnail =
        cachedOutputs.find((o) => o.type === OutputType.THUMBNAIL && o.modelState === representation)?.filePath ?? '';

      // re-create variant
      const cachedVariant: CachedVariant = {
        isCached: true,
        tenancyId: projectId,
        contentId: productId,
        variantId,
        release: variantPayload.release,
        context: JSON.parse(cachedMetaInfo?.context ?? '{}') as DCTemplateContext,
        name: cachedMetaInfo?.name ?? '',
        dataSetLocation: cachedMetaInfo?.dataSetLocation ?? '',
        inputs: variantPayload.inputs as VariantInput[],
        thumbnail,
        schemaVersion: Number(cachedMetaInfo?.schemaVersion ?? '1'),
        outputs: cachedOutputs.map(transformCachedOutputToVariantOutput),
        createdAt: '',
        updatedAt: '',
      };
      return cachedVariant;
    }
  }
  return null;
};

interface CreateVariantArgs {
  projectId: string;
  productId: string;
  variantPayload: PostVariantPayload;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
}

export const createVariant = async ({
  projectId,
  productId,
  variantPayload,
  incomingAccBridgeData,
}: CreateVariantArgs): Promise<Variant> => {
  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  return dcApiService.postVariant({
    projectId,
    productId,
    postVariantPayload: variantPayload,
    incomingAccBridgeData,
  });
};

interface GetVariantOutputsArgs {
  variant: Variant;
  representation?: string;
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams;
}

export const getVariantOutputs = async ({
  variant,
  representation,
  incomingAccBridgeData,
}: GetVariantOutputsArgs): Promise<VariantOutput[]> => {
  const { outputs, variantId, tenancyId, name: variantName } = variant;

  try {
    const variantOutputs: VariantOutput[] = [];

    let cached = await getLocallyCached(variantId);

    if (
      !cached ||
      !representation ||
      !cached.some((cachedOuput) => cachedOuput.type === OutputType.RFA && cachedOuput.modelState === representation)
    ) {
      // not found in cache, retrieve from API
      if (outputs.some((o) => !o.urn)) {
        // only cache variant if all outputs were successful
        return outputs;
      }

      // cache meta
      const metaInfo = {
        ...translateToCachedVariantMetaInfo(variant),
      } as FileMetaData;

      await writeToCache(variantId, 'VARIANTINFO', metaInfo);

      await Promise.all(
        outputs.map(async (output) => {
          if (!output.urn) {
            return Promise.resolve();
          }

          // loop through all the properties of variant output
          // and assign the value to outputFileMetaData
          const outputFileMetaData = {} as FileMetaData;
          for (const key in output) {
            if (output.hasOwnProperty(key)) {
              const val = output[key as keyof typeof output];
              if (val !== undefined) {
                outputFileMetaData[key] = val;
              }
            }
          }

          const downloadURLPayload: DownloadURLPayload = {
            objectKey: output.urn,
          };
          const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
          const downloadURLResponse = await dcApiService.downloadURL({
            projectId: tenancyId,
            downloadURLPayload,
            incomingAccBridgeData,
          });
          const name = output.type === OutputType.RFA ? `${variantName}.rfa` : output.type;
          await downloadFileToLocalCache(variantId, downloadURLResponse.signedUrl, name, output.type, outputFileMetaData);

          return Promise.resolve();
        }),
      );

      cached = await getLocallyCached(variantId);
    }

    const cachedOutputs = cached?.filter((c) => Object.values<string>(OutputType).includes(c.type));
    if (cached && cachedOutputs.length > 0) {
      variantOutputs.push(...cachedOutputs.map(transformCachedOutputToVariantOutput));
    }
    return variantOutputs;
  } catch (err: any) {
    logError(err);
    // do nothing
    return [];
  }
};
function exhaustiveOutputTypeCheck(_: never, message: string): never {
  throw new Error(message);
}
const transformCachedOutputToVariantOutput = (cachedOutput: CachedFileInfo): VariantOutput => {
  try {
    const outputType = cachedOutput.type as OutputTypes;
    switch (outputType) {
      case OutputType.BOM:
      case OutputType.GLB:
      case OutputType.STEP:
      case OutputType.STL:
      case OutputType.SAT:
      case OutputType.THUMBNAIL:
        return {
          type: outputType,
          urn: cachedOutput.filePath,
          status: OutputStatus.SUCCESS,
          modelState: cachedOutput['modelState'],
        };
      case OutputType.DWG:
      case OutputType.IDW:
        return {
          type: outputType,
          urn: cachedOutput.filePath,
          status: OutputStatus.SUCCESS,
          drawingTemplatePath: cachedOutput['drawingTemplatePath'],
          excludeIntellectualProperty: JSON.parse(cachedOutput['excludeIntellectualProperty']),
        };
      case OutputType.IAM:
        return {
          type: outputType,
          urn: cachedOutput.filePath,
          status: OutputStatus.SUCCESS,
        };
      case OutputType.PDF:
        return {
          type: outputType,
          urn: cachedOutput.filePath,
          status: OutputStatus.SUCCESS,
          drawingTemplatePath: cachedOutput['drawingTemplatePath'],
        };
      case OutputType.RFA:
        return {
          type: outputType,
          urn: cachedOutput.filePath,
          status: OutputStatus.SUCCESS,
          modelState: cachedOutput['modelState'],
          category: cachedOutput['category'],
          family: cachedOutput['family'],
        };
      default:
        // Exaustive check to avoid missing any output type
        exhaustiveOutputTypeCheck(outputType, `Unknow type = ${cachedOutput.type}`);
    }
  } catch (error: unknown) {
    logError(error);
    throw Error('Failed in transformCachedOutputToVariantOutput()');
  }
};

interface DownloadVariantLogFileArgs {
  projectId: string;
  productId: string;
  variantId: string;
  productName: string;
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams;
}

export const downloadVariantLogFile = async ({
  projectId,
  productId,
  variantId,
  productName,
  incomingAccBridgeData,
}: DownloadVariantLogFileArgs): Promise<string> => {
  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  const variantLog = await dcApiService.getVariantLog({ projectId, productId, variantId });
  const fileName = `${productName}_${variantLog.createdAt}.txt`;
  const downloadURLPayload: DownloadURLPayload = {
    objectKey: variantLog.urn,
  };
  const downloadUrl: DownloadUrlResponse = await dcApiService.downloadURL({
    projectId,
    downloadURLPayload,
    incomingAccBridgeData,
  });
  return await downloadFileFromUrl(downloadUrl.signedUrl, fileName.replace(/[/\\?%*:|"<>]/g, ''));
};
