import { DCRfaOutput } from '@adsk/offsite-dc-sdk';
import { DropdownItem } from '@mid-react-common/addins';
import { ConfigurableProductProperties } from '@mid-react-common/common';
import { isDCRfaOutput } from 'mid-utils';
import { useMemo } from 'react';

interface UseRepresentationState {
  productReleaseRFAOutput?: DCRfaOutput;
  selectedRepresentation: string | undefined;
  rfaRepresentationDropdownItems: DropdownItem[];
  selectedRepresentationDropdownItem: DropdownItem | null;
  handleRfaRepresentationSelection: ({ selectedItem }: { selectedItem?: DropdownItem | null }) => void;
}

interface useRepresentationProps {
  configurableProductProperties: ConfigurableProductProperties;
  selectedRepresentation: string | undefined;
  setSelectedRepresentation: (selectedRepresentation: string | undefined) => void;
  handleRepresentationStateChange?: () => void;
}

export const useRepresentation = ({
  configurableProductProperties,
  selectedRepresentation,
  setSelectedRepresentation,
  handleRepresentationStateChange,
}: useRepresentationProps): UseRepresentationState => {
  const { productReleaseRFAOutput, rfaRepresentationDropdownItems } = useMemo(() => {
    const productReleaseRFAOutput = configurableProductProperties.outputs.find(isDCRfaOutput);

    const rfaRepresentationDropdownItems =
      productReleaseRFAOutput?.options?.modelStates?.map((modelState) => ({
        id: modelState,
        value: modelState,
        label: modelState,
      })) || [];

    return { productReleaseRFAOutput, rfaRepresentationDropdownItems };
  }, [configurableProductProperties.outputs]);

  const handleRfaRepresentationSelection = ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
    if (selectedItem && typeof selectedItem.value === 'string') {
      setSelectedRepresentation(selectedItem.value);
      if (handleRepresentationStateChange) {
        handleRepresentationStateChange();
      }
    }
  };

  return {
    productReleaseRFAOutput,
    selectedRepresentation,
    rfaRepresentationDropdownItems,
    selectedRepresentationDropdownItem: selectedRepresentation
      ? { id: selectedRepresentation, label: selectedRepresentation, value: selectedRepresentation }
      : null,
    handleRfaRepresentationSelection,
  };
};
