import { Variant } from '@adsk/offsite-dc-sdk';
import { DropdownItem } from '@mid-react-common/addins';
import { isVariantOutputSuccessfulRfaOutput } from 'mid-utils';
import { useMemo, useRef } from 'react';

interface UseRepresentationToInsertState {
  variantRfaRepresentations: string[];
  variantRepresentationDropdownItems: DropdownItem[];
  selectedVariantRepresentationDropdownItemToInsert: DropdownItem | null;
  selectedVariantRepresentationToInsert: string | undefined;
  handleSelectVariantRfaRepresentationForInsert: ({ selectedItem }: { selectedItem?: DropdownItem | null }) => void;
  isLoadingVariantRfaRepresentations: boolean;
}

interface useRepresentationToInsertProps {
  selectedRepresentation: string | undefined;
  setSelectedRepresentation: (selectedRepresentation: string | undefined) => void;
  selectedVariant: Variant | undefined | null;
  handleRepresentationStateChange?: () => void;
}

export const useRepresentationToInsertReplace = ({
  selectedRepresentation,
  setSelectedRepresentation,
  selectedVariant,
  handleRepresentationStateChange,
}: useRepresentationToInsertProps): UseRepresentationToInsertState => {
  const isLoadingVariantRfaRepresentations = useRef(true);
  const handleSelectVariantRfaRepresentationForInsert = ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
    if (selectedItem && typeof selectedItem.value === 'string') {
      setSelectedRepresentation(selectedItem.value);
      if (handleRepresentationStateChange) {
        handleRepresentationStateChange();
      }
    }
  };

  const { variantRfaRepresentations, variantRepresentationDropdownItems } = useMemo(() => {
    const variantRfaRepresentations =
      selectedVariant?.outputs.reduce<string[]>((representations, variantOutput) => {
        if (isVariantOutputSuccessfulRfaOutput(variantOutput)) {
          return [...representations, variantOutput.modelState];
        }

        return representations;
      }, []) || [];

    const variantRepresentationDropdownItems = variantRfaRepresentations.map((modelState) => ({
      id: modelState,
      value: modelState,
      label: modelState,
    }));

    if (selectedVariant && isLoadingVariantRfaRepresentations.current) {
      isLoadingVariantRfaRepresentations.current = false;
    }

    return { variantRfaRepresentations, variantRepresentationDropdownItems };
  }, [selectedVariant]);

  const selectedVariantRepresentationDropdownItemToInsert = useMemo(
    () =>
      selectedRepresentation && variantRfaRepresentations.includes(selectedRepresentation)
        ? { id: selectedRepresentation, label: selectedRepresentation, value: selectedRepresentation }
        : variantRepresentationDropdownItems[0],
    [selectedRepresentation, variantRepresentationDropdownItems, variantRfaRepresentations],
  );

  const selectedVariantRepresentationToInsert = useMemo(
    () => selectedVariantRepresentationDropdownItemToInsert?.value.toString(),
    [selectedVariantRepresentationDropdownItemToInsert],
  );

  return {
    variantRfaRepresentations,
    variantRepresentationDropdownItems,
    selectedVariantRepresentationDropdownItemToInsert,
    selectedVariantRepresentationToInsert,
    handleSelectVariantRfaRepresentationForInsert,
    isLoadingVariantRfaRepresentations: isLoadingVariantRfaRepresentations.current,
  };
};
