import { Variant } from '@adsk/offsite-dc-sdk';
import { StateSetter } from '@mid-react-common/common';
import { VariantFormState, VariantFormStates } from '../../interfaces/typings';

export interface ModalState {
  isOpen: boolean;
  dontShowAgain?: boolean;
  dontShowAgainMessage?: string;
  title?: string | JSX.Element;
  message: string | JSX.Element;
  onConfirmCallback?: Function;
  isConfirmCallbackAsync?: boolean;
  onSecondaryConfirmCallback?: Function;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  disableConfirmButton?: boolean;
  secondaryConfirmButtonLabel?: string;
  disableSecondaryConfirmButton?: boolean;
}

interface UseProductCustomizationFooterProps {
  setVariantFormState: StateSetter<VariantFormStates>;
  resetConfigurableProductProperties: () => void;
  setSelectedCachedVariant: (value: React.SetStateAction<Variant | null>) => void;
  setIsFormInitializing: StateSetter<boolean>;
  setCachedVariantThumbnail: StateSetter<string | undefined>;
}

interface UseProductCustomizationFooterState {
  handleResetClick: () => void;
}

const useProductCustomizationFooter = ({
  setVariantFormState,
  resetConfigurableProductProperties,
  setSelectedCachedVariant,
  setIsFormInitializing,
  setCachedVariantThumbnail,
}: UseProductCustomizationFooterProps): UseProductCustomizationFooterState => {
  const handleResetClick = (): void => {
    resetConfigurableProductProperties();
    setIsFormInitializing(true);
    setVariantFormState(VariantFormState.DEFAULT_VARIANT);
    setSelectedCachedVariant(null);
    setCachedVariantThumbnail(undefined);
  };

  return {
    handleResetClick,
  };
};

export default useProductCustomizationFooter;
