import { FlexContainer, LeftButton, NotificationContext, PreviewWrapper } from '@mid-react-common/common';
import {
  NameAndRelease,
  ProductDetailsTabs,
  ScrollableContainer,
  VariantFormState,
  VariantThumbnail,
  useFetchingListOfVariants,
  useGeneratedVariantsDataGrid,
  useProductDetailsTabs,
  useRepresentation,
  useRepresentationToInsertReplace,
} from '@mid-react-common/revit-components';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ReplaceFooter from 'components/ReplaceFooter/ReplaceFooter';
import DataContext from 'context/Data.context';
import { testIds } from 'global/dataTestIds';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import text from '../../global/mid-edit-form.text.json';
import { SelectScreenContainer } from './SelectScreen.styles';
import { useHandleReplaceRFA } from './useHandleReplaceRFA';

const SelectScreen: React.FC = () => {
  const { showNotification } = useContext(NotificationContext);
  const {
    rfaInstance,
    currentProductRelease,
    selectedCachedVariant,
    currentVariantOfInstance,
    setSelectedCachedVariant,
    updateConfigurableProductInputs,
    configurableProductProperties,
    reFetchCachedVariants,
    setReFetchCachedVariants,
    cachedVariantsList,
    setCachedVariantsList,
    variantFormState,
    setVariantFormState,
    selectedRepresentation,
    setSelectedRepresentation,
    selectedVariantThumbnailVisible,
    setSelectedVariantThumbnailVisible,
    productReleasesListLength,
    postedVariantIdsList,
  } = useContext(DataContext);

  const incomingAccBridgeData = useMemo(
    () => ({
      sourceFolderUrn: rfaInstance?.sourceFolderUrn,
      sourceProjectId: rfaInstance?.sourceProjectId,
      targetProjectId: rfaInstance?.targetProjectId,
    }),
    [rfaInstance],
  );

  const [thumbnail, setThumbnail] = useState(selectedCachedVariant?.thumbnail || currentProductRelease?.thumbnail);

  // logic to handle the tabs
  const { tabValue, handleTabChange } = useProductDetailsTabs();

  // logic to fetch the list of variants, in order to feed DataGrid
  const { filteredVariantsListByCurrentRelease, isLoadingVariantsList, handleRefreshVariants } = useFetchingListOfVariants({
    currentProductRelease,
    cachedVariantsList,
    reFetchCachedVariants,
    postedVariantIdsList,
    incomingAccBridgeData,
    setReFetchCachedVariants,
    showNotification,
  });

  // logic to load data into DataGrid and handle DataGrid selections
  const { dataGridRows, dataGridColumns, rowSelectionModel, onRowSelectionModelChange } = useGeneratedVariantsDataGrid({
    cachedVariantsList,
    productReleaseInputs: configurableProductProperties.inputs,
    reFetchCachedVariants,
    selectedCachedVariant,
    setThumbnail,
    setSelectedCachedVariant,
    setVariantFormState,
    updateConfigurableProductInputs,
    setSelectedRepresentation,
    showNotification,
    formRules: currentProductRelease?.rules?.formRules?.code,
  });

  // logic to handle the representation dropdown
  const { productReleaseRFAOutput } = useRepresentation({
    configurableProductProperties,
    selectedRepresentation,
    setSelectedRepresentation,
  });

  // logic to handle the selected Variant representation dropdown to insert
  const {
    variantRfaRepresentations,
    variantRepresentationDropdownItems,
    selectedVariantRepresentationDropdownItemToInsert,
    selectedVariantRepresentationToInsert,
    handleSelectVariantRfaRepresentationForInsert,
  } = useRepresentationToInsertReplace({
    selectedRepresentation,
    setSelectedRepresentation,
    selectedVariant: selectedCachedVariant,
  });

  const { handleReplaceRFA } = useHandleReplaceRFA({ selectedRepresentation: selectedVariantRepresentationToInsert });

  useEffect(() => {
    if (!filteredVariantsListByCurrentRelease) {
      return;
    }

    setCachedVariantsList(filteredVariantsListByCurrentRelease);
  }, [filteredVariantsListByCurrentRelease, setCachedVariantsList]);

  const isSameVariantOfInstanceSelected =
    selectedCachedVariant?.variantId === currentVariantOfInstance?.variantId &&
    rfaInstance?.modelState === selectedRepresentation;

  return (
    <>
      <SelectScreenContainer>
        <ScrollableContainer>
          <FlexContainer justifyContent="space-between">
            {currentProductRelease && (
              <NameAndRelease
                currentProductRelease={currentProductRelease}
                productReleasesListLength={productReleasesListLength}
              />
            )}
            <LeftButton
              variant="outlined"
              color="secondary"
              data-testid={testIds.previewButton}
              onClick={() => setSelectedVariantThumbnailVisible(!selectedVariantThumbnailVisible)}
              endIcon={selectedVariantThumbnailVisible ? <Visibility /> : <VisibilityOff />}
            >
              {text.preview}
            </LeftButton>
          </FlexContainer>

          <PreviewWrapper display={selectedVariantThumbnailVisible ? '' : 'none'}>
            <VariantThumbnail
              tenancyId={currentProductRelease?.tenancyId}
              isLoading={variantFormState === VariantFormState.VARIANT_RFA_OUTPUT_PENDING}
              thumbnail={thumbnail}
              alternateMessage={
                variantFormState === VariantFormState.VARIANT_RFA_OUTPUT_PENDING ? text.generatingNewVariant : undefined
              }
              incomingAccBridgeData={incomingAccBridgeData}
            />
          </PreviewWrapper>

          <ProductDetailsTabs
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            productReleaseRFAOutput={productReleaseRFAOutput}
            productReleaseNotes={currentProductRelease?.notes}
            variantRfaRepresentations={variantRfaRepresentations}
            reFetchCachedVariants={reFetchCachedVariants}
            isLoadingVariantsList={isLoadingVariantsList}
            handleRefreshVariantDataGrid={handleRefreshVariants}
            dataGridRows={dataGridRows}
            dataGridColumns={dataGridColumns}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={onRowSelectionModelChange}
            isProductReleaseConfigurable={currentProductRelease?.isConfigurable}
            authoringAppEngine={currentProductRelease?.context.engine.location}
          />
        </ScrollableContainer>
      </SelectScreenContainer>
      <ReplaceFooter
        handleReplaceRFA={handleReplaceRFA}
        variantFormState={variantFormState}
        hasVariantSelected={!!selectedCachedVariant}
        representationDropdownItems={variantRepresentationDropdownItems}
        handleRfaRepresentationSelection={handleSelectVariantRfaRepresentationForInsert}
        selectedRepresentationDropdownItem={selectedVariantRepresentationDropdownItemToInsert}
        currentReleaseStatus={currentProductRelease?.status}
        isSameVariantOfInstanceSelected={isSameVariantOfInstanceSelected}
      />
    </>
  );
};

export default SelectScreen;
