import DataContext from 'context/Data.context';
import { editInstance, getVariantOutputs } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import {
  analytics,
  ANALYTICS_ACTIONS,
  MIDEditFormAppMoniker,
  NOTIFICATION_STATUSES,
  NotificationContext,
} from '@mid-react-common/common';
import { areAllVariantOutputsFinished } from '@mid-react-common/addins';
import { useContext, useMemo } from 'react';
import { VariantFormState } from '@mid-react-common/revit-components';
import { ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { MIDSelectedRFAInstance } from 'global/selectedRFAInstance.utils';
import { DCProductUIExtension } from 'mid-types';
import text from '../../global/mid-edit-form.text.json';

// Segment event handler
const handleSegmentInstanceReplaceEvent = (
  sessionId: string,
  selectedCachedVariant: Variant,
  currentProductRelease: DCProductUIExtension<ProductRelease>,
  selectedRepresentation: string | undefined,
  rfaInstance: MIDSelectedRFAInstance | undefined,
) => {
  analytics.track(ANALYTICS_ACTIONS.MIDEF.replaceInstance, {
    session_id: sessionId,
    sourceMoniker: MIDEditFormAppMoniker,
    variantName: selectedCachedVariant.name,
    variantId: selectedCachedVariant.variantId,
    releaseNumber: currentProductRelease.release,
    projectId: currentProductRelease.tenancyId,
    productId: currentProductRelease.contentId,
    hasRules: !!currentProductRelease.rules,
    numberOfInputs: currentProductRelease.inputs.length,
    releaseStatus: currentProductRelease.status,
    selectedRepresentation: selectedRepresentation || rfaInstance?.modelState,
  });
};

interface UseHandleReplaceRFAProps {
  selectedRepresentation: string | undefined;
}

interface UseHandleReplaceRFA {
  handleReplaceRFA: () => Promise<void>;
}

export const useHandleReplaceRFA = ({ selectedRepresentation }: UseHandleReplaceRFAProps): UseHandleReplaceRFA => {
  const {
    sessionId,
    rfaInstance,
    setRfaInstance,
    currentProductRelease,
    selectedCachedVariant,
    setVariantFormState,
    setCurrentVariantOfInstance,
  } = useContext(DataContext);

  const incomingAccBridgeData = useMemo(
    () => ({
      sourceFolderUrn: rfaInstance?.sourceFolderUrn,
      sourceProjectId: rfaInstance?.sourceProjectId,
      targetProjectId: rfaInstance?.targetProjectId,
    }),
    [rfaInstance],
  );

  const { showNotification } = useContext(NotificationContext);

  const handleReplaceRFA = async () => {
    if (!rfaInstance) {
      return;
    }

    const representationToReplace = selectedRepresentation || rfaInstance?.modelState;
    if (!selectedCachedVariant || !areAllVariantOutputsFinished(selectedCachedVariant.outputs) || !representationToReplace) {
      return;
    }

    setVariantFormState(VariantFormState.REPLACING_VARIANT);

    try {
      // TODO: getVariantOutputs call will be removed. See https://jira.autodesk.com/browse/TRADES-5698
      await getVariantOutputs({
        variant: selectedCachedVariant,
        representation: selectedRepresentation,
        incomingAccBridgeData,
      });

      await editInstance(selectedCachedVariant, representationToReplace, incomingAccBridgeData);
      showNotification({
        message: text.MIDEditForm.changesSuccessfullyAppliedSuccessMessage,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });

      setCurrentVariantOfInstance(selectedCachedVariant);
      // update the instance with the new representation (so that the Replace button will be disabled saying that the variant with the same representation is already applied)
      setRfaInstance({ ...rfaInstance, modelState: selectedRepresentation });

      if (currentProductRelease) {
        handleSegmentInstanceReplaceEvent(
          sessionId,
          selectedCachedVariant,
          currentProductRelease,
          representationToReplace,
          rfaInstance,
        );
      }
    } catch (err: unknown) {
      logError(Error(text.MIDEditForm.couldNotApplyChangesErrorMessage), { selectedCachedVariant });
      showNotification({
        message: text.MIDEditForm.couldNotApplyChangesErrorMessage,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    } finally {
      setVariantFormState(VariantFormState.EXISTING_VARIANT_SELECTED);
    }
  };

  return {
    handleReplaceRFA,
  };
};
