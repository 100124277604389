import { Dropdown, DropdownItem } from '@mid-react-common/addins';
import { VariantFormState, VariantFormStates } from '@mid-react-common/revit-components';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import text from '../../global/mid-edit-form.text.json';
import { RepresentationDropdownWrapper } from './RepresentationDropdown.styles';
import { testIds } from '../../global/dataTestIds';

interface RepresentationDropdownProps {
  representationDropdownItems: DropdownItem[];
  variantFormState: VariantFormStates;
  selectedRepresentationDropdownItem: DropdownItem | null;
  handleRfaRepresentationSelection: ({ selectedItem }: { selectedItem?: DropdownItem | null }) => void;
}

const RepresentationDropdown: React.FC<RepresentationDropdownProps> = ({
  representationDropdownItems,
  variantFormState,
  selectedRepresentationDropdownItem,
  handleRfaRepresentationSelection,
}) => {
  const theme = useTheme();

  return (
    <RepresentationDropdownWrapper isVisible={!!representationDropdownItems.length}>
      <Dropdown
        elements={representationDropdownItems}
        label={text.MIDEditForm.representations}
        selectedItem={selectedRepresentationDropdownItem}
        width={`${theme.var.wideInputControl}px`}
        disabled={
          representationDropdownItems.length === 1 ||
          variantFormState === VariantFormState.EDITING_NEW_VARIANT ||
          variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
          variantFormState === VariantFormState.REPLACING_VARIANT
        }
        onSelect={handleRfaRepresentationSelection}
        data-testid={testIds.representationDropdownToReplace}
      />
    </RepresentationDropdownWrapper>
  );
};

export default RepresentationDropdown;
